import firebase from "firebase/app";
import "firebase/firestore";
import { REF_NOTIFICATION, GET_NOTIFICATION_SUCCESS } from "../ref";

// -------------------------- Notification Snap --------------------------

const subNotification = [];
export const firestoreSnapNotification = (callback) => (dispatch) => {
  const notificationRef = firebase.firestore().collection(REF_NOTIFICATION);
  const unsub = notificationRef
    .orderBy("createAt", "desc")
    .limit(10)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getNotificationSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
  subNotification.push(unsub);
};

const getNotificationSuccess = (dispatch, data) => {
  dispatch({
    type: GET_NOTIFICATION_SUCCESS,
    payload: data,
  });
};

export const unSnapNotification = () => (dispatch) => {
  subNotification.forEach((subscriber) => {
    subscriber();
  });
  subNotification.length = 0;
};

// -------------------------- Operator Add --------------------------

export const firestoreAddNotification =
  ({ username, title, body }, callback) =>
  (dispatch) => {
    const notificationRef = firebase.firestore().collection(REF_NOTIFICATION);
    const id = notificationRef.doc().id;
    const data = {
      id,
      username,
      title,
      body,
      topic: "Client",
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    notificationRef
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };
