import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  CircularProgress,
  Paper,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { SearchOutlined, CloudDownloadOutlined } from "@material-ui/icons";
import numeral from "numeral";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { sumBy } from "lodash";
import Navbar from "../00_Nav/nav_bar";
import { firestoreGetUserInfo } from "../../store/actions/user.action";
import { firestoreGetF1 } from "../../store/actions/mlm.action";
import ShareTable from "./sharing_table";
import { exportToCSV } from "./functions/export_csv";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";

class ShareScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      from: moment().startOf("month"),
      to: moment().endOf("month"),
      orderBy: "createAt",
      order: "desc",
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperatorDetail();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperatorDetail();
  };

  // ====================== Functions ====================== //

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  // ====================== Firebase Functions ====================== //

  handleSearchByEmail = () => {
    const { search } = this.state;
    if (search) {
      this.setState(
        {
          loading: true,
          error: "",
          seed: 0,
        },
        () => {
          this.props.firestoreGetUserInfo({ email: search }, (user) => {
            if (user) {
              this.setState(
                {
                  user,
                  loading: false,
                },
                () => this.updateData()
              );
            } else {
              this.setState({
                error: "Không tìm thấy",
                loading: false,
              });
            }
          });
        }
      );
    }
  };

  updateData = () => {
    const { from, to, user } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        const id = user?.id;
        if (id) {
          this.props.firestoreGetF1({ id, from, to }, () => {
            this.setState({
              loading: false,
            });
          });
        }
      }
    );
  };

  // ====================== Render Component ====================== //

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p1");
    if (detail) {
      if (allow) {
        return (
          <main>
            {this.renderSearchByEmail()}
            {this.renderForm()}
            {this.renderTableContainer()}
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderSearchByEmail = () => {
    const { search, error, loading } = this.state;
    return (
      <TextField
        margin="dense"
        label="Tìm kiếm email"
        variant="outlined"
        className="email"
        fullWidth
        error={!!error}
        helperText={error}
        value={search || ""}
        onChange={(e) => this.handleSearchChange(e)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            this.handleSearchByEmail();
            ev.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: loading ? (
            <CircularProgress size={15} />
          ) : (
            <InputAdornment position="end">{this.renderSearchButton()}</InputAdornment>
          ),
        }}
      />
    );
  };

  renderSearchButton = () => {
    const { searchLoading } = this.state;
    if (searchLoading) {
      return (
        <IconButton aria-label="search email" disabled>
          <CircularProgress size={15} />
        </IconButton>
      );
    }
    return (
      <IconButton aria-label="search email" onClick={() => this.handleSearchByEmail()}>
        <SearchOutlined />
      </IconButton>
    );
  };

  renderForm = () => {
    const { f1 } = this.props;
    const { user } = this.state;
    const balance = user?.balance || 0;
    const fullName = user?.fullName || "";
    const phone = user?.phone || "";
    const totalSeed = balance ? numeral(balance).format("0,0") : "0";
    const total = sumBy(f1, "spent");
    const format = numeral(total).format("0,0");
    return (
      <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <strong>Túi hạt hiện có: </strong> {totalSeed}
          </Grid>
          <Grid item xs={6}>
            <strong>Họ và tên: </strong> {fullName}
          </Grid>
          <Grid item xs={6}>
            <strong>Phone: </strong> {phone}
          </Grid>
          <Grid item xs={6}>
            <strong>Tổng số F1: </strong>{" "}
            <span style={{ color: "blue", fontWeight: "bold" }}>{f1?.length}</span>
          </Grid>
          <Grid item xs={6}>
            <strong>Tổng hạt đã dùng: </strong>{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>{format}</span>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderTableContainer = () => {
    return (
      <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
        {this.renderFromToPicker()}
        <ShareTable />
      </Paper>
    );
  };

  renderFromToPicker = () => {
    const { from, to } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="Từ"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <DatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="Đến"
              format="DD/MM/YYYY"
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderButton()}
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderExport()}
        </Grid>
      </Grid>
    );
  };

  renderButton = () => {
    const { from, to, loading, user } = this.state;
    const disabled = !(from && to && user && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : null}
        onClick={() => this.updateData()}
        style={{ fontSize: 13, color: "#fff" }}
      >
        Xem
      </Button>
    );
  };

  renderExport = () => {
    const { loading, user } = this.state;
    const { f1 } = this.props;
    const disabled = !(f1 && user && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : <CloudDownloadOutlined size={15} />}
        onClick={() => exportToCSV({ report: f1, user })}
        style={{ fontSize: 13, color: "#fff", backgroundColor: disabled ? "#ccc" : "#1976d2" }}
      >
        Tải
      </Button>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="white">
          <Navbar title="Tra cứu F1" subtitle="Táo Vàng" right={null} />
          {this.renderBody()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  f1: state.mlmReducer.f1,
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreGetUserInfo,
  firestoreGetF1,
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ShareScene);
