import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Erp Sanbox
export const BRAND_NAME = "Táo Vàng"; // App Name
export const SANDBOX = false;
export const SYSTEM_EMAIL = "system_account_do_not_delete_taovang@gmail.com";
export const SYSTEM_PASSWORD = 'NxbxK`v"3W3C3}fp';
export const VERSION = "v0.1.7";
const firebaseConfig = {
  apiKey: "AIzaSyAXoKdErm04ELTdZcOLR3l8B9f7RmOpvV0",
  authDomain: "tao-vang-b7d29.firebaseapp.com",
  projectId: "tao-vang-b7d29",
  storageBucket: "tao-vang-b7d29.appspot.com",
  messagingSenderId: "902235985805",
  appId: "1:902235985805:web:54eb764db894044d784fc4",
  measurementId: "G-VZNMXF9XKB",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const authApp = firebase.initializeApp(firebaseConfig, "authApp");

// ========================= App String ========================= //
export const URL = {
  // url
  home: "/",
  notification: "/notification",
  sharing: "/sharing",
  event: "/event",
  chain: "/chain",
  request: "/request",
  report: "/report",
  reportClient: "/report-client",
};
