import { GET_OPERATOR_SUCCESS, GET_OPERATOR_DETAIL_SUCCESS } from "../ref";

const initState = {
  operator: null,
  detail: null,
};

const operatorReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_OPERATOR_SUCCESS:
      return {
        ...state,
        operator: action.payload,
      };
    case GET_OPERATOR_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    default:
      return state;
  }
};

export default operatorReducer;
