import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreDeleteChain } from "../../../store/actions/chain.action";

class DialogDelete extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    // state
    this.state = {
      error: "",
      loading: false,
    };
  }

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  handleDelete = () => {
    const { data } = this.props;
    firestoreDeleteChain({ chain: data }); // không cần chờ response
    this.props.handleClose();
  };

  // ====================== Render Component ====================== //

  renderDeleteButton = () => {
    const { loading } = this.state;
    return (
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "red", color: "#fff" }}
        startIcon={loading ? <CircularProgress size={15} /> : null}
        onClick={this.handleDelete}
      >
        Xóa
      </Button>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { error, loading } = this.state;
    const { dialogOpen, handleClose, data } = this.props;
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Xóa "{data?.title}"</DialogTitle>
        <DialogContent dividers>
          <p>Bạn có chắc chắn xóa sự kiện này? Thao tác không thể phục hồi.</p>
          <div className="red-text center">{error ? <p>{error}</p> : null}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Hủy
          </Button>
          {this.renderDeleteButton()}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DialogDelete);
