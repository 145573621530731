import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  TextField,
  CircularProgress,
  Paper,
  Button,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import IOSSwitch from "../../../component/iosSwitch";
import { firestoreAddEvent } from "../../../store/actions/event.action";
import { EVENTS } from "../../../config/stringConfig";
import EventImage from "./event_image";

class EventAdd extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      title: "",
      author: "",
      description: "",
      zoomId: "",
      password: "",
      address: "",
      zoomLink: "",
      image: null,
      type: "zoom",
      vip: false,
      time: moment(),
      loading: false,
    };
  }

  resetData = () => {
    this.setState({
      title: "",
      author: "",
      description: "",
      zoomId: "",
      password: "",
      address: "",
      zoomLink: "",
      image: null,
      type: "zoom",
      vip: false,
      time: moment(),
      loading: false,
    });
  };

  // ====================== Functions ====================== //

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleTime = (time) => {
    this.setState({
      time,
    });
  };

  handleImage = (image) => {
    this.setState({
      image,
    });
  };

  handleVip = () => {
    const { vip } = this.state;
    this.setState({
      vip: !vip,
    });
  };

  // ====================== Firebase Functions ====================== //

  addEvent = () => {
    const {
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      time,
      vip,
    } = this.state;
    const timeStamp = moment(time).toDate();
    const event = {
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      vip,
      time: timeStamp,
      active: false,
    };
    this.setState(
      {
        loading: true,
      },
      () => {
        firestoreAddEvent({ event }, () => {
          this.resetData();
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderType = () => {
    const { type } = this.state;
    return (
      <Grid item xs={6}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel htmlFor="age-native-required">Loại sự kiện</InputLabel>
          <Select value={type} name="type" onChange={this.handleChange}>
            {EVENTS?.map((item, index) => {
              return (
                <option value={item.value} key={index}>
                  Zoom
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderTime = () => {
    const { time } = this.state;
    return (
      <Grid item xs={6}>
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          inputVariant="outlined"
          label="Thời gian"
          value={time}
          onChange={this.handleTime}
          disablePast
          size="small"
          format="DD/MM/YYYY HH:mm"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderImage = () => {
    const { image } = this.state;
    return (
      <Grid item xs={12}>
        <EventImage image={image} handleChange={this.handleImage} />
      </Grid>
    );
  };

  renderTitle = () => {
    const { title } = this.state;
    const length = title ? 100 - title?.length : 100;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tiêu đề"
          variant="outlined"
          size="small"
          name="title"
          value={title}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAuthor = () => {
    const { author } = this.state;
    const length = author ? 100 - author?.length : 100;
    return (
      <Grid item xs={8} className="count-string">
        <TextField
          label="Tác giả"
          variant="outlined"
          size="small"
          name="author"
          value={author}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderVip = () => {
    const { vip } = this.state;
    const name = vip ? "Có phí" : "Miễn phí";
    return (
      <Grid item xs={4} className="count-string">
        <FormControlLabel
          style={{ float: "right" }}
          className="draw-switch"
          control={<IOSSwitch checked={vip} onChange={() => this.handleVip()} />}
          label={name}
        />
      </Grid>
    );
  };

  renderDescription = () => {
    const { description } = this.state;
    const length = description ? 1000 - description?.length : 1000;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tóm tắt"
          variant="outlined"
          size="small"
          name="description"
          value={description}
          required
          inputProps={{ maxLength: 1000 }}
          multiline
          rows={4}
          onChange={this.handleChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAddress = () => {
    const { address } = this.state;
    const length = address ? 250 - address?.length : 250;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Địa chỉ"
          variant="outlined"
          size="small"
          name="address"
          value={address}
          inputProps={{ maxLength: 250 }}
          onChange={this.handleChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderZoomId = () => {
    const { zoomId, password, type } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      return (
        <React.Fragment>
          <Grid item xs={6} className="count-string">
            <TextField
              label="ID Zoom"
              variant="outlined"
              size="small"
              name="zoomId"
              value={zoomId}
              required
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="count-string">
            <TextField
              label="Zoom Password"
              variant="outlined"
              size="small"
              name="password"
              value={password}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      );
    }
  };

  renderZoomLink = () => {
    const { zoomLink, type } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      return (
        <Grid item xs={12} className="count-string">
          <TextField
            label="Zoom Link"
            variant="outlined"
            size="small"
            name="zoomLink"
            value={zoomLink}
            onChange={this.handleChange}
            fullWidth
          />
        </Grid>
      );
    }
  };

  renderButton = () => {
    const { loading, title, description, zoomId, image, type, time } = this.state;
    const requireZoom = type === "zoom" ? true : false;
    const valid = requireZoom && zoomId ? zoomId : true;
    const enable = title && description && valid && image && type && time;
    const disabled = !enable || loading;
    return (
      <Grid
        item
        xs={12}
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 30,
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ color: "#fff", fontSize: 16 }}
          startIcon={loading ? <CircularProgress size={15} style={{ color: "#fff" }} /> : null}
          disabled={disabled}
          onClick={() => this.addEvent()}
        >
          Thêm sự kiện
        </Button>
      </Grid>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <Paper elevation={2} style={{ padding: 10 }}>
        <Grid container spacing={2}>
          {this.renderType()}
          {this.renderTime()}
          {this.renderImage()}
          {this.renderTitle()}
          {this.renderAuthor()}
          {this.renderVip()}
          {this.renderDescription()}
          {this.renderZoomId()}
          {this.renderZoomLink()}
          {this.renderAddress()}
          {this.renderButton()}
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventAdd);
