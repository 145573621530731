import FileSaver from "file-saver";
import moment from "moment";
import { BRAND_NAME } from "../../../config/config";
import { jsonToCSV } from "react-papaparse";

const columns = ["fullName", "email", "phone", "count", "info"];

export const exportToCSV = ({ report, name }) => {
  // =========================  Provider =========================
  // // =========================  Row Data =========================
  const rowTableHeader = "Họ Và Tên,Email,Phone,Số lần,Chi tiết\n";
  const rowTableBody = jsonToCSV(report, { columns: columns, header: false, quotes: true });
  // // =========================  CSV =========================
  const csvBuffer = `${rowTableHeader}${rowTableBody}`;
  const currentTime = moment().format("DD_MM_YYYY-HH_mm_ss");
  const fileName = `${BRAND_NAME}-thong-tin-${name}-${currentTime}`;
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
