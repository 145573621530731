import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { Loading } from "../../component/loading";

class ClientTable extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      header: [
        { id: "createAt", label: "Đăng ký", align: "left", sortable: false },
        { id: "fullName", label: "Họ và tên", align: "left", sortable: false },
        { id: "email", label: "Email", align: "left", sortable: false },
        { id: "phone", label: "Phone", align: "left", sortable: false },
      ],
    };
  }

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { header, orderBy, order } = this.state;
    return (
      <TableHead>
        <TableRow>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding="default"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {this.renderTableSortLabel(headCell)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell) => {
    const { orderBy, order } = this.state;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { data } = this.props;
    if (data !== null) {
      return (
        <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableRow = ({ item, index }) => {
    const createAt = item?.createAt;
    const time =
      (createAt?.seconds && moment(createAt?.toDate()).format("DD/MM/YYYY HH:mm:ss")) || "";
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{time}</TableCell>
        <TableCell align="left">{item?.fullName}</TableCell>
        <TableCell align="left">{item?.email}</TableCell>
        <TableCell align="left">{item?.phone}</TableCell>
      </TableRow>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <TableContainer className="report-table">
        <Table stickyHeader aria-label="sticky table">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, null))(ClientTable);
