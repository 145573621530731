import { F1_LIST_SUCCESS } from "../ref";

const initState = {
  f1: [],
};

const mlmReducer = (state = initState, action) => {
  switch (action.type) {
    case F1_LIST_SUCCESS:
      return {
        ...state,
        f1: action.payload,
      };
    default:
      return state;
  }
};

export default mlmReducer;
