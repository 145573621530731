import { GET_CLIENT_LIST_SUCCESS } from "../ref";

const initState = {
  clients: null,
};

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
