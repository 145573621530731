import lodash from "lodash";
import moment from "moment";

export const refineData = ({ source }, callback) => {
  const selected =
    lodash(source)
      .groupBy("client")
      .map((data, client) => {
        const fullName = data && data[0]?.fullName;
        const email = data && data[0]?.email;
        const phone = data && data[0]?.phone;
        const list = data?.map((item) => {
          const createAt = item?.createAt;
          const dateString = createAt?.seconds
            ? moment(createAt.toDate()).format("DD/MM/YYYY hh:ss A")
            : "";
          return dateString;
        });
        const info = list?.join(", ");
        const count = list?.length;
        return {
          client,
          fullName,
          info,
          count,
          email,
          phone,
          list,
        };
      })
      .value() || [];
  const data = (selected && lodash.orderBy(selected, ["count"], ["desc"])) || [];
  if (callback) {
    callback(data);
  }
};

export const refineInvitation = ({ invitations }, callback) => {
  const selected =
    lodash(invitations)
      .groupBy("fromClient")
      .map((data, fromClient) => {
        const fullName = data && data[0]?.fromFullName;
        const email = data && data[0]?.fromEmail;
        const phone = data && data[0]?.fromPhone;
        const list = data?.map((item) => `${item?.toEmail} (${item?.toPhone})`);
        const info = list?.join(", ");
        const count = list?.length;
        return {
          client: fromClient,
          fullName,
          info,
          count,
          email,
          phone,
          list,
        };
      })
      .value() || [];
  const data = (selected && lodash.orderBy(selected, ["count"], ["desc"])) || [];
  console.log("invitations", invitations);
  console.log("data", data);
  if (callback) {
    callback(data);
  }
};
