import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import EmptyScene from "./empty_scene";
import SignInScene from "../01_Login/login";
import DashboardScene from "../02_Dashboard/dashboard";
import NotificationScene from "../03_Notification/notification";
import ShareScene from "../04_Sharing/sharing";
import EventScene from "../05_Event/event";
import ChainScene from "../05_Chain/chain";
import RequestScreen from "../05_Request/request";
import ReportScene from "../06_Report/report";
import ReportClientScene from "../07_Client/client";

import { URL } from "../../config/config";

class Router extends Component {
  renderStacks = () => {
    const { auth } = this.props;
    if (auth?.uid) {
      return (
        <Switch>
          <Route path={URL.home} component={DashboardScene} exact />
          <Route path={URL.notification} component={NotificationScene} />
          <Route path={URL.sharing} component={ShareScene} />
          <Route path={URL.event} component={EventScene} />
          <Route path={URL.chain} component={ChainScene} />
          <Route path={URL.request} component={RequestScreen} />
          <Route path={URL.report} component={ReportScene} />
          <Route path={URL.reportClient} component={ReportClientScene} />
          <Route component={EmptyScene} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route path={URL.home} component={SignInScene} exact />
        <Route component={EmptyScene} />
      </Switch>
    );
  };

  render() {
    return <BrowserRouter>{this.renderStacks()}</BrowserRouter>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

export default connect(mapStateToProps, null)(Router);
