import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { CssBaseline, Container, CircularProgress, Paper, Button, Grid } from "@material-ui/core";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import ClientTable from "./client_table";
import { exportToCSV } from "./functions/export_csv";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";
import { firestoreGetClient } from "../../store/actions/client.action";

class ReportClientScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      orderBy: "count",
      order: "desc",
      type: "draw",
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperatorDetail();
    this.updateData();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperatorDetail();
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
      data: [],
    });
  };

  handleExport = () => {
    const { from, to } = this.state;
    const { clients } = this.props;
    const report = this.refineData(clients);
    exportToCSV({ report, from, to });
  };

  // ====================== Firebase Functions ====================== //

  updateData = () => {
    const { from, to } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreGetClient({ from, to }, () => {
          this.setState({
            loading: false,
          });
        });
      }
    );
  };

  refineData = (clients) => {
    const data = clients?.map((item) => {
      const createAt = item?.createAt;
      const time =
        (createAt?.seconds && moment(createAt?.toDate()).format("DD/MM/YYYY HH:mm:ss")) || "";
      const source = "App";
      return {
        ...item,
        time,
        source,
      };
    });
    return data;
  };

  // ====================== Render Component ====================== //

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p5");
    if (detail) {
      if (allow) {
        return (
          <main>
            <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
              {this.renderFromToPicker()}
              {this.renderTables()}
            </Paper>
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderFromToPicker = () => {
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item sm={4} xs={6}>
          {this.renderFrom()}
        </Grid>
        <Grid item sm={4} xs={6}>
          {this.renderTo()}
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderButton()}
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderExport()}
        </Grid>
      </Grid>
    );
  };

  renderFrom = () => {
    const { from, to } = this.state;
    return (
      <DatePicker
        autoOk
        className="date-picker"
        variant="inline"
        inputVariant="outlined"
        fullWidth
        value={from}
        maxDate={to}
        label="Từ"
        format="DD/MM/YYYY"
        onChange={(date) => this.handleDateChange(date, "from")}
        margin="dense"
      />
    );
  };

  renderTo = () => {
    const { from, to } = this.state;
    return (
      <DatePicker
        autoOk
        className="date-picker"
        variant="inline"
        inputVariant="outlined"
        fullWidth
        minDate={from}
        value={to}
        label="Đến"
        format="DD/MM/YYYY"
        onChange={(date) => this.handleDateChange(date, "to")}
        margin="dense"
      />
    );
  };

  renderButton = () => {
    const { from, to, loading, type } = this.state;
    const disabled = !(from && to && type && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : null}
        onClick={() => this.updateData()}
        style={{ fontSize: 13, color: "#fff" }}
      >
        Xem
      </Button>
    );
  };

  renderExport = () => {
    const { clients } = this.props;
    const { loading } = this.state;
    const disabled = !(clients?.length > 0 && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : <CloudDownloadOutlined size={15} />}
        onClick={() => this.handleExport()}
        style={{ fontSize: 13, color: "#fff", backgroundColor: disabled ? "#ccc" : "#1976d2" }}
      >
        Tải
      </Button>
    );
  };

  renderTables = () => {
    const { clients } = this.props;
    if (clients) {
      return <ClientTable data={clients} />;
    }
    return null;
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="md" className="white">
          <Navbar title="Thống kê" subtitle="Táo Vàng" right={null} />
          {this.renderBody()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: state.operatorReducer.detail,
  clients: state.clientReducer.clients,
});

const mapDispatchToProps = {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
  firestoreGetClient,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportClientScene);
