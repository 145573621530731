import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  CircularProgress,
  Paper,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { CloudDownloadOutlined } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import ReportTable from "./report_table";
import { exportToCSV } from "./functions/export_csv";
import { refineData, refineInvitation } from "./functions/refine_data";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";
import {
  firestoreGetReportDraw,
  firestoreGetReportInvitation,
  firestoreGetReportKid,
  firestoreGetReportParent,
} from "../../store/actions/report.action";

const TYPE = [
  { name: "Rút thẻ (lần đầu trong ngày)", value: "draw", file: "rut-the" },
  { name: "Gửi lời mời", value: "invitation", file: "loi-moi" },
  { name: "Hoàn tất hành trình con trẻ (lần đầu trong ngày)", value: "kid", file: "con-tre" },
  { name: "Hoàn tất hành trình cha mẹ (lần đầu trong ngày)", value: "parent", file: "cha-me" },
];

class ReportScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      orderBy: "count",
      order: "desc",
      type: "draw",
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperatorDetail();
    this.updateData();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperatorDetail();
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
      data: [],
    });
  };

  handleExport = () => {
    const { data, type } = this.state;
    const file = TYPE.find((item) => item.value === type)?.file || "";
    exportToCSV({ report: data, name: file });
  };

  // ====================== Firebase Functions ====================== //

  updateData = () => {
    const { from, to, type } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        if (type === "draw") {
          this.props.firestoreGetReportDraw({ from, to }, () => {
            const { draws } = this.props;
            refineData({ source: draws }, (data) => {
              this.setState({
                data,
                loading: false,
              });
            });
          });
        } else if (type === "invitation") {
          this.props.firestoreGetReportInvitation({ from, to }, () => {
            const { invitations } = this.props;
            refineInvitation({ invitations }, (data) => {
              this.setState({
                data,
                loading: false,
              });
            });
          });
        } else if (type === "kid") {
          this.props.firestoreGetReportKid({ from, to }, () => {
            const { kids } = this.props;
            refineData({ source: kids }, (data) => {
              this.setState({
                data,
                loading: false,
              });
            });
          });
        } else if (type === "parent") {
          this.props.firestoreGetReportParent({ from, to }, () => {
            const { parents } = this.props;
            refineData({ source: parents }, (data) => {
              this.setState({
                data,
                loading: false,
              });
            });
          });
        }
      }
    );
  };

  // ====================== Render Component ====================== //

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p4");
    if (detail) {
      if (allow) {
        return (
          <main>
            <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
              {this.renderFromToPicker()}
              {this.renderTables()}
            </Paper>
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderFromToPicker = () => {
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item sm={12} xs={12}>
          {this.renderTypeDropDown()}
        </Grid>
        <Grid item sm={4} xs={6}>
          {this.renderFrom()}
        </Grid>
        <Grid item sm={4} xs={6}>
          {this.renderTo()}
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderButton()}
        </Grid>
        <Grid item sm={2} xs={6}>
          {this.renderExport()}
        </Grid>
      </Grid>
    );
  };

  renderTypeDropDown = () => {
    const { type } = this.state;
    const menuItem = TYPE.map((item, index) => {
      return (
        <MenuItem key={index} value={item.value}>
          {item.name}
        </MenuItem>
      );
    });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="typeLabel">Loại Thống Kê</InputLabel>
        <Select
          labelId="typeLabel"
          id="type"
          value={type || ""}
          onChange={(event) => this.handleSelect(event, "type")}
          label="Loại Thống Kê"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderFrom = () => {
    const { from, to } = this.state;
    return (
      <DatePicker
        autoOk
        className="date-picker"
        variant="inline"
        inputVariant="outlined"
        fullWidth
        value={from}
        maxDate={to}
        label="Từ"
        format="DD/MM/YYYY"
        onChange={(date) => this.handleDateChange(date, "from")}
        margin="dense"
      />
    );
  };

  renderTo = () => {
    const { from, to } = this.state;
    return (
      <DatePicker
        autoOk
        className="date-picker"
        variant="inline"
        inputVariant="outlined"
        fullWidth
        minDate={from}
        value={to}
        label="Đến"
        format="DD/MM/YYYY"
        onChange={(date) => this.handleDateChange(date, "to")}
        margin="dense"
      />
    );
  };

  renderButton = () => {
    const { from, to, loading, type } = this.state;
    const disabled = !(from && to && type && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : null}
        onClick={() => this.updateData()}
        style={{ fontSize: 13, color: "#fff" }}
      >
        Xem
      </Button>
    );
  };

  renderExport = () => {
    const { loading, data } = this.state;
    const disabled = !(data && !loading);
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
        startIcon={loading ? <CircularProgress size={15} /> : <CloudDownloadOutlined size={15} />}
        onClick={() => this.handleExport()}
        style={{ fontSize: 13, color: "#fff", backgroundColor: disabled ? "#ccc" : "#1976d2" }}
      >
        Tải
      </Button>
    );
  };

  renderTables = () => {
    const { data } = this.state;
    if (data) {
      return <ReportTable data={data} />;
    }
    return null;
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="white">
          <Navbar title="Thống kê" subtitle="Táo Vàng" right={null} />
          {this.renderBody()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  draws: state.reportReducer.draws,
  invitations: state.reportReducer.invitations,
  kids: state.reportReducer.kids,
  parents: state.reportReducer.parents,
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
  firestoreGetReportDraw,
  firestoreGetReportInvitation,
  firestoreGetReportKid,
  firestoreGetReportParent,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportScene);
