import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import {
  Menu,
  MicNoneOutlined,
  AccountBalanceOutlined,
  ShareOutlined,
  PowerSettingsNewOutlined,
  LiveTvOutlined,
  AssignmentTurnedInOutlined,
  AssignmentIndOutlined,
} from "@material-ui/icons";
import logo from "../../config/img/logo.png";
import { firebaseLogout } from "../../store/actions/login.action";
import { URL, SANDBOX } from "../../config/config";

const MENU = [
  { href: URL.home, label: "Home", icon: <AccountBalanceOutlined /> },
  { href: URL.notification, label: "Thông báo", icon: <MicNoneOutlined /> },
  { href: URL.sharing, label: "Tra cứu F1", icon: <ShareOutlined /> },
  { href: URL.event, label: "Sự kiện đơn", icon: <LiveTvOutlined /> },
  { href: URL.chain, label: "Sự liện chuỗi", icon: <LiveTvOutlined /> },
  { href: URL.request, label: "Xét duyệt", icon: <AssignmentTurnedInOutlined /> },
  { href: URL.report, label: "Thống kê", icon: <AssignmentIndOutlined /> },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const SignInLink = (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <div className="root-container">
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          {props.left ? (
            props.left
          ) : (
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={{
                borderRadius: 5,
                border: "1px solid",
                padding: 5,
                marginLeft: 0,
              }}
            >
              <Menu />
            </IconButton>
          )}
          <div className="title-cover">
            <Typography variant="h6" noWrap>
              {props.title ? props.title : ""}
              {SANDBOX ? " - SandBox" : ""}
            </Typography>
            <span className="subtitle">{props.subtitle ? props.subtitle : ""}</span>
          </div>
          <div className="appbar-right">{props.right ? props.right : null}</div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} style={{ textAlign: "center" }}>
          <img src={logo} alt="logo" className="nav-logo" />
        </div>
        <Divider />
        <List>
          {MENU?.map((item, index) => renderItem({ item, index }))}
          {ItemLogout(props)}
        </List>
      </Drawer>
    </div>
  );
};

const renderItem = ({ item, index }) => {
  const href = item.href;
  const label = item.label;
  const icon = item.icon;
  return (
    <ListItem button component="a" href={href} key={index}>
      <Tooltip title={label} aria-label={label} placement="top">
        <ListItemIcon>{icon}</ListItemIcon>
      </Tooltip>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
};

const ItemLogout = (props) => {
  return (
    <ListItem button onClick={props.firebaseLogout}>
      <ListItemIcon>
        <PowerSettingsNewOutlined />
      </ListItemIcon>
      <ListItemText>Đăng xuất</ListItemText>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  firebaseLogout,
})(SignInLink);
