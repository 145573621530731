import { GET_CHAIN_SUCCESS } from "../ref";

const initState = {
  chains: null,
};

const chainReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CHAIN_SUCCESS:
      return {
        ...state,
        chains: action.payload,
      };
    default:
      return state;
  }
};

export default chainReducer;
