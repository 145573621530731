import FileSaver from "file-saver";
import moment from "moment";
import { BRAND_NAME } from "../../../config/config";
import { jsonToCSV } from "react-papaparse";

export const exportToCSV = ({ report, from, to }) => {
  // =========================  Provider =========================
  // // =========================  Row Data =========================
  const rowTableHeader =
    "MÃ KH,TÊN KHÁCH HÀNG,ĐIỆN THOẠI,ĐỊA CHỈ,EMAIL KH,GIỚI TÍNH,SINH NHẬT,MÔ TẢ,MỐI QUAN HỆ,TRẠNG THÁI XẾP LỚP,SALE PHỤ TRÁCH,NHÓM KHÁCH HÀNG,NGUỒN DATA,NGÀY TẠO,KHÁCH HÀNG CỦA,TRẠNG THÁI THANH TOÁN,TRẠNG THÁI GỬI QUÀ,NGƯỜI GIỚI THIỆU,QUỐC GIA,TỈNH/THÀNH PHỐ,QUẬN/HUYỆN,NGÀNH KINH DOANH,MÃ SỐ THUẾ,KINH ĐỘ,VĨ ĐỘ,NGƯỜI LIÊN HỆ CHÍNH,DANH XƯNG NGƯỜI LIÊN HỆ CHÍNH,ĐIỆN THOẠI NGƯỜI LIÊN HỆ CHÍNH,GIỚI TÍNH NGƯỜI LIÊN HỆ,SINH NHẬT LIÊN HỆ CHÍNH,EMAIL NGƯỜI LIÊN HỆ CHÍNH,CHỨC VỤ LIÊN HỆ CHÍNH,FAX,WEBSITE\n";
  const count = rowTableHeader.split(",").length - 14;
  const extra = new Array(count).fill("");
  const columns = [
    "",
    "fullName",
    "phone",
    "",
    "email",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "source",
    "time",
    ...extra,
  ];
  const rowTableBody = jsonToCSV(report, { columns: columns, header: false, quotes: true });
  // // =========================  CSV =========================
  const csvBuffer = `${rowTableHeader}${rowTableBody}`;
  const currentTime = moment().unix();
  const formatFrom = moment(from).format("DD_MM_YYYY");
  const formatTo = moment(to).format("DD_MM_YYYY");
  const fileName = `${BRAND_NAME}-thong-tin-nguoi-dung-${formatFrom}-${formatTo}-${currentTime}`;
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
