import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  TextField,
  Paper,
  Button,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import IOSSwitch from "../../../component/iosSwitch";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { firestoreUpdateEvent } from "../../../store/actions/event.action";
import { EVENTS } from "../../../config/stringConfig";
import EventImage from "./event_image";
import DialogDelete from "../dialogs/event_dialog_delete";

class EventItem extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      title: "",
      author: "",
      description: "",
      zoomId: "",
      password: "",
      zoomLink: "",
      address: "",
      image: null,
      type: "zoom",
      vip: false,
      loading: false,
      edit: false,
      dialogDelete: false,
    };
  }

  // ====================== Dialog ====================== //

  openDialogDelete = () => {
    this.setState({
      dialogDelete: true,
    });
  };

  closeDialogDelete = () => {
    this.setState({
      dialogDelete: false,
    });
  };

  // ====================== Functions ====================== //

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleTime = (time) => {
    this.setState({
      time,
    });
  };

  handleImage = (image) => {
    this.setState({
      image,
    });
  };

  handleVip = () => {
    const { vip } = this.state;
    this.setState({
      vip: !vip,
    });
  };

  handleEdit = () => {
    const { event } = this.props;
    const time = event?.time?.seconds ? moment(event?.time?.toDate()) : moment();
    this.setState({
      title: event?.title,
      author: event?.author,
      description: event?.description,
      zoomId: event?.zoomId,
      password: event?.password,
      vip: event?.vip,
      zoomLink: event?.zoomLink,
      address: event?.address,
      image: event?.image,
      type: event?.type,
      time,
      edit: true,
    });
  };

  stopEdit = () => {
    this.setState({
      edit: false,
    });
  };

  // ====================== Firebase Functions ====================== //

  handleSave = () => {
    const { event } = this.props;
    const {
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      time,
      vip,
    } = this.state;
    const timeStamp = moment(time).toDate();
    const update = {
      id: event.id,
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      vip,
      time: timeStamp,
    };
    firestoreUpdateEvent({ event: update });
    this.setState({
      edit: false,
    });
  };

  handleActive = () => {
    const { event } = this.props;
    const old = event?.active || false;
    const update = {
      ...event,
      active: !old,
    };
    firestoreUpdateEvent({ event: update });
  };

  // ====================== Render Component ====================== //

  renderHeader = () => {
    const { edit } = this.state;
    if (!edit) {
      return (
        <React.Fragment>
          <Grid item sm={3} xs={3}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                color: edit ? "#333" : "#fff",
                fontSize: 13,
                marginTop: 10,
                backgroundColor: edit ? "#ccc" : "#115293",
              }}
              onClick={() => this.handleEdit()}
            >
              Sửa
            </Button>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                color: edit ? "#333" : "#fff",
                fontSize: 13,
                marginTop: 10,
                backgroundColor: edit ? "#ccc" : "red",
              }}
              onClick={() => this.openDialogDelete()}
            >
              Xóa
            </Button>
          </Grid>
          <Grid item sm={6} xs={6}>
            {this.renderSwitch()}
          </Grid>
        </React.Fragment>
      );
    }
  };

  renderSwitch = () => {
    const { event } = this.props;
    const value = event?.active || false;
    const name = value ? "Đang chạy" : "Đang tắt";
    return (
      <FormControlLabel
        style={{ float: "right" }}
        className="draw-switch"
        control={<IOSSwitch checked={value} onChange={() => this.handleActive()} />}
        label={name}
      />
    );
  };

  renderType = () => {
    const { event } = this.props;
    const { type, edit } = this.state;
    const value = edit ? type : event?.type;
    return (
      <Grid item xs={6}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel htmlFor="age-native-required">Loại sự kiện</InputLabel>
          <Select disabled={!edit} value={value} name="type" onChange={this.handleChange}>
            {EVENTS?.map((item, index) => {
              return (
                <option value={item.value} key={index}>
                  Zoom
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderTime = () => {
    const { event } = this.props;
    const { time, edit } = this.state;
    const eTime = event?.time?.seconds ? moment(event?.time?.toDate()) : moment();
    const value = edit ? time : eTime;
    return (
      <Grid item xs={6}>
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          disabled={!edit}
          inputVariant="outlined"
          label="Thời gian"
          value={value}
          onChange={this.handleTime}
          disablePast
          size="small"
          format="DD/MM/YYYY HH:mm"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderImage = () => {
    const { event } = this.props;
    const { image, edit } = this.state;
    const value = edit ? image : event?.image;
    return (
      <Grid item xs={12}>
        <EventImage image={value} handleChange={this.handleImage} disabled={!edit} />
      </Grid>
    );
  };

  renderTitle = () => {
    const { event } = this.props;
    const { title, edit } = this.state;
    const value = edit ? title : event?.title;
    const length = value ? 100 - value?.length : 100;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tiêu đề"
          variant="outlined"
          size="small"
          name="title"
          value={value}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          disabled={!edit}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAuthor = () => {
    const { event } = this.props;
    const { author, edit } = this.state;
    const value = edit ? author : event?.author;
    const length = value ? 100 - value?.length : 100;
    return (
      <Grid item xs={8} className="count-string">
        <TextField
          label="Tác giả"
          variant="outlined"
          size="small"
          name="author"
          value={value}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          disabled={!edit}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderVip = () => {
    const { event } = this.props;
    const { vip, edit } = this.state;
    const value = edit ? vip : event?.vip;
    const name = value ? "Có phí" : "Miễn phí";
    return (
      <Grid item xs={4} className="count-string">
        <FormControlLabel
          style={{ float: "right" }}
          className="draw-switch"
          disabled={!edit}
          control={<IOSSwitch checked={value} onChange={() => this.handleVip()} />}
          label={name}
        />
      </Grid>
    );
  };

  renderDescription = () => {
    const { event } = this.props;
    const { description, edit } = this.state;
    const value = edit ? description : event?.description;
    const length = value ? 1000 - value?.length : 1000;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tóm tắt"
          variant="outlined"
          size="small"
          name="description"
          value={value}
          required
          inputProps={{ maxLength: 1000 }}
          multiline
          rows={4}
          onChange={this.handleChange}
          disabled={!edit}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAddress = () => {
    const { event } = this.props;
    const { address, edit } = this.state;
    const value = edit ? address : event?.address;
    const length = value ? 250 - value?.length : 250;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Địa chỉ"
          variant="outlined"
          size="small"
          name="address"
          value={value}
          inputProps={{ maxLength: 250 }}
          onChange={this.handleChange}
          disabled={!edit}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderZoomId = () => {
    const { event } = this.props;
    const { zoomId, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const value = edit ? zoomId : event?.zoomId;
      return (
        <Grid item xs={12} className="count-string">
          <TextField
            label="ID Zoom "
            variant="outlined"
            size="small"
            name="zoomId"
            value={value}
            disabled={!edit}
            required
            inputProps={{ maxLength: 250 }}
            onChange={this.handleChange}
            fullWidth
          />
        </Grid>
      );
    }
  };

  renderZoomId = () => {
    const { event } = this.props;
    const { zoomId, password, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const selectedId = edit ? zoomId : event?.zoomId;
      const selectedPasword = edit ? password : event?.password;
      return (
        <React.Fragment>
          <Grid item xs={6} className="count-string">
            <TextField
              label="ID Zoom"
              variant="outlined"
              size="small"
              name="zoomId"
              value={selectedId}
              required
              disabled={!edit}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="count-string">
            <TextField
              label="Zoom Password"
              variant="outlined"
              size="small"
              name="password"
              disabled={!edit}
              value={selectedPasword}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      );
    }
  };

  renderZoomLink = () => {
    const { event } = this.props;
    const { zoomLink, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const value = edit ? zoomLink : event?.zoomLink;
      return (
        <Grid item xs={12} className="count-string">
          <TextField
            label="Zoom Link"
            variant="outlined"
            size="small"
            name="zoomLink"
            value={value}
            disabled={!edit}
            onChange={this.handleChange}
            fullWidth
          />
        </Grid>
      );
    }
  };

  renderButtonSave = () => {
    const { edit } = this.state;
    if (edit) {
      return (
        <React.Fragment>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                fontSize: 13,
              }}
              onClick={() => this.stopEdit()}
            >
              Hủy
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              style={{
                fontSize: 13,
              }}
              onClick={() => this.handleSave()}
            >
              Lưu
            </Button>
          </Grid>
        </React.Fragment>
      );
    }
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { event } = this.props;
    const { dialogDelete } = this.state;
    return (
      <Paper elevation={2} style={{ padding: 10, marginBottom: 30 }}>
        <Grid container spacing={2}>
          {this.renderHeader()}
          {this.renderType()}
          {this.renderTime()}
          {this.renderImage()}
          {this.renderTitle()}
          {this.renderAuthor()}
          {this.renderVip()}
          {this.renderDescription()}
          {this.renderZoomId()}
          {this.renderZoomLink()}
          {this.renderAddress()}
          {this.renderButtonSave()}
        </Grid>
        <DialogDelete data={event} dialogOpen={dialogDelete} handleClose={this.closeDialogDelete} />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EventItem);
