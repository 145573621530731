// local
export const LOCAL_USER = "Local User";
// Task
// firebase ref
export const REF_CLIENT = "client";
export const REF_LOG = "log";
export const REF_OPERATOR = "operator";
export const REF_NOTIFICATION = "notification";
export const REF_EVENT = "event";
export const REF_CHAIN = "chain";
export const REF_REQUEST = "request";
export const REF_REPORT = "report";
// redux ref
export const LOGIN_LOADING = "firebase_login_loading";
export const LOGIN_SUCCESS = "firebase_login_success";
export const LOGIN_FAILED = "firebase_login_fail";
export const LOGOUT_SUCCESS = "firebase_logout_success";

export const LOG_LIST_SUCCESS = "get_log_list_success";
export const GET_OPERATOR_SUCCESS = "firebase_get_operator_success";
export const GET_OPERATOR_DETAIL_SUCCESS = "firebase_get_operator_detail_success";
export const GET_NOTIFICATION_SUCCESS = "firebase_get_notification_success";
export const GET_EVENT_SUCCESS = "firebase_get_event_success";
export const GET_CHAIN_SUCCESS = "firebase_get_chain_success";
export const GET_REQUEST_SUCCESS = "firebase_get_request_success";
export const GET_CHAIN_REQUEST_SUCCESS = "firebase_get_chain_request_success";
export const F1_LIST_SUCCESS = "get_f1_list_success";

export const GET_REPORT_DRAW_SUCCESS = "firebase_get_report_draw_success";
export const GET_REPORT_INVITATION_SUCCESS = "firebase_get_report_invitation_success";
export const GET_REPORT_JOURNEY_PARENT_SUCCESS = "firebase_get_report_journey_parent_success";
export const GET_REPORT_JOURNEY_KID_SUCCESS = "firebase_get_report_journey_kid_success";

export const GET_CLIENT_LIST_SUCCESS = "firebase_get_client_list_success";
