import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { firebaseLogin } from "../../store/actions/login.action";
import { firestoreSnapOperator, unSnapOperator } from "../../store/actions/operator.action";
import { BRAND_NAME } from "../../config/config";
import logo from "../../config/img/logo.png";

class SignIn extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperator();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperator();
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { operator } = this.props;
    const selected = operator.find((item) => item.username === username);
    if (selected) {
      if (password === selected?.password) {
        this.setState(
          {
            error: "",
          },
          () => {
            const id = selected?.id;
            this.props.firebaseLogin({ id });
          }
        );
      } else {
        this.setState({
          error: "Sai mật khẩu.",
        });
      }
    } else {
      this.setState({
        error: "Tài khoản không tồn tại.",
      });
    }
  };

  // -------------------------- RENDER --------------------------

  renderCopyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="#">
          {BRAND_NAME}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  renderLoginButton() {
    const { loading, operator } = this.props;
    const disabled = loading || !operator;
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className="submit"
        disabled={disabled}
      >
        Đăng nhập {loading ? <CircularProgress className="spinner" size={15} /> : null}
      </Button>
    );
  }

  // -------------------------- MAIN --------------------------
  render() {
    const { error } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className="paper">
          <img src={logo} className="login-logo" alt="branch-logo" />
          <form
            className="form"
            noValidate
            onSubmit={this.handleSubmit}
            style={{ marginTop: "20px" }}
          >
            <Typography component="h1" variant="h5" align="center">
              Đăng nhập
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Tài khoản"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange}
            />
            <FormControlLabel
              control={<Checkbox checked value="remember" color="primary" />}
              label="Ghi nhớ đăng nhập"
            />
            {this.renderLoginButton()}
          </form>
          <div className="red-text center">{error ? <p>{error}</p> : null}</div>
        </div>
        <Box mt={8}>{this.renderCopyright()}</Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  loading: state.loginReducer.loading,
  operator: state.operatorReducer.operator,
});

const mapDispatchToProps = {
  firebaseLogin,
  firestoreSnapOperator,
  unSnapOperator,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
