import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { REF_CLIENT, GET_CLIENT_LIST_SUCCESS } from "../ref";

// -------------------------- Report Draw --------------------------
export const firestoreGetClient =
  ({ from, to }, callback) =>
  (dispatch) => {
    const clientRef = firebase.firestore().collection(REF_CLIENT);
    const start = moment(from).startOf("day").toDate();
    const end = moment(to).endOf("day").toDate();
    clientRef
      .where("createAt", ">=", start)
      .where("createAt", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getClientSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getClientSuccess = (dispatch, data) => {
  dispatch({
    type: GET_CLIENT_LIST_SUCCESS,
    payload: data,
  });
};
