import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import loginReducer from "./login.reducer";
import operatorReducer from "./operator.reducer";
import notificationReducer from "./notification.reducer";
import mlmReducer from "./mlm.reducer";
import eventReducer from "./event.reducer";
import requestReducer from "./request.reducer";
import chainReducer from "./chain.reducer";
import reportReducer from "./report.reducer";
import clientReducer from "./client.reducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  loginReducer,
  operatorReducer,
  notificationReducer,
  mlmReducer,
  eventReducer,
  requestReducer,
  chainReducer,
  reportReducer,
  clientReducer,
});

export default rootReducer;
