import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Loading } from "../../component/loading";

class ReportTable extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      header: [
        { id: "fullName", label: "Họ và tên", align: "left", sortable: false },
        { id: "count", label: "Số lần", align: "right", sortable: false },
      ],
    };
  }

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { header, orderBy, order } = this.state;
    return (
      <TableHead>
        <TableRow>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding="default"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {this.renderTableSortLabel(headCell)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell) => {
    const { orderBy, order } = this.state;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { data } = this.props;
    console.log({ data });
    if (data !== null) {
      return (
        <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableRow = ({ item, index }) => {
    return (
      <TableRow hover key={index}>
        <TableCell align="left">
          <div style={{ fontSize: 12, fontWeight: "bold" }}>
            {item?.fullName} - {item?.email} - {item?.phone}
          </div>
          <div>
            <p>Chi tiết:</p>
            <ol>
              {item?.list?.map((i, s) => (
                <li key={s} style={{ fontSize: 12, color: "blue" }}>
                  {i}
                </li>
              ))}
            </ol>
          </div>
        </TableCell>
        <TableCell align="right">{item?.count}</TableCell>
      </TableRow>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <TableContainer className="report-table">
        <Table stickyHeader aria-label="sticky table">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, null))(ReportTable);
