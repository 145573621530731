import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  IconButton,
  ButtonGroup,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CheckCircleOutline,
  BlockOutlined,
  BallotOutlined,
  HelpOutline,
  SearchOutlined,
} from "@material-ui/icons";
import moment from "moment";
import { EVENT_ALL, EVENT_APPROVE, EVENT_DENY, EVENT_WAITING } from "../../config/stringConfig";
import { Loading } from "../../component/loading";
import {
  firestoreSnapRequest,
  unSnapRequest,
  firestoreUpdateRequest,
} from "../../store/actions/request.actions";
import { firestoreGetEvent } from "../../store/actions/event.action";

class RequestEvent extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      event: null,
      events: null,
      loading: false,
      status: EVENT_ALL,
      search: "",
    };
  }

  componentDidMount = () => {
    firestoreGetEvent((events) => {
      this.setState({
        events,
      });
    });
  };

  componentWillUnmount = () => {
    this.props.unSnapRequest();
  };

  refineData = () => {
    const { status, selected } = this.state;
    const { requests } = this.props;
    let data = requests;
    if (status !== EVENT_ALL) {
      data = requests.filter((item) => item.status === status);
    }
    if (selected) {
      data = requests.filter((item) => item.id === selected.id);
    }
    this.setState({
      data,
    });
  };

  // -------------------------- FUNCTIONS --------------------------

  handleChangeStatus = ({ value }) => {
    const { status } = this.state;
    if (value === status) {
      return;
    }
    this.setState(
      {
        status: value,
      },
      () => this.refineData()
    );
  };

  handleSelect = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getRequest();
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => this.refineData()
    );
  };

  selectRequest = (selected) => {
    this.setState(
      {
        selected,
      },
      () => this.refineData()
    );
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  getRequest = () => {
    const { event } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.unSnapRequest();
        setTimeout(() => {
          const eventId = event?.id;
          this.props.firestoreSnapRequest({ eventId }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.refineData()
            );
          });
        }, 500);
      }
    );
  };

  updateStatus = ({ item, status }) => {
    const { detail } = this.props;
    const editor = detail?.username;
    const id = item.id;
    firestoreUpdateRequest({ id, status, editor });
  };

  // -------------------------- RENDER --------------------------

  renderSelectEvent = () => {
    const { events, event } = this.state;
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel htmlFor="age-native-required">Chọn Sự Kiện</InputLabel>
        <Select value={event} name="event" onChange={this.handleSelect}>
          {events?.map((item, index) => {
            const title = item?.title;
            const time =
              item?.time?.seconds && moment(item?.time?.toDate()).format("DD/MM/YYYY HH:mm");
            const vip = item?.vip ? "[Vip] " : "[Free] ";
            const label = `${vip}${title} ${time}`;
            return (
              <MenuItem value={item} key={index}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  renderFilterBar = () => {
    const { status } = this.state;
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
        <Grid item>Lọc theo: </Grid>
        <Grid item>
          <ButtonGroup aria-label="outlined primary button group" style={{ marginTop: 5 }}>
            <Button
              className={`filter-button ${status === EVENT_ALL ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: EVENT_ALL })}
            >
              <BallotOutlined />
            </Button>
            <Button
              className={`filter-button ${status === EVENT_WAITING ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: EVENT_WAITING })}
            >
              <HelpOutline />
            </Button>
            <Button
              className={`filter-button ${status === EVENT_APPROVE ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: EVENT_APPROVE })}
            >
              <CheckCircleOutline />
            </Button>
            <Button
              className={`filter-button ${status === EVENT_DENY ? "active" : ""}`}
              onClick={() => this.handleChangeStatus({ value: EVENT_DENY })}
            >
              <BlockOutlined />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };

  renderSearch = () => {
    const { keyAutocomplete } = this.state;
    const { requests } = this.props;
    const disabled = !requests;
    return (
      <Autocomplete
        id="search"
        key={keyAutocomplete}
        onChange={(e, value) => this.selectRequest(value)}
        getOptionSelected={(option) => option}
        getOptionLabel={(option) => {
          return `${option?.fullName} | ${option?.phone} | ${option?.email}`;
        }}
        options={requests || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Tên, số điện thoại, email ..."
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  renderTable = () => {
    const { events, event } = this.state;
    if (events) {
      return (
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Đăng ký</TableCell>
                <TableCell>Họ & Tên</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                {event?.vip ? (
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    Xét duyệt
                  </TableCell>
                ) : null}
                {event?.vip ? <TableCell align="right">Sửa cuối</TableCell> : null}
              </TableRow>
            </TableHead>
            {this.renderTableBody()}
          </Table>
        </TableContainer>
      );
    }
  };

  renderTableBody = () => {
    const { loading, data } = this.state;
    if (!loading) {
      return (
        <TableBody>{data?.map((item, index) => this.renderTableRow({ item, index }))}</TableBody>
      );
    }
    return <Loading loading={true} />;
  };

  renderTableRow = ({ item, index }) => {
    const createAt = item?.createAt;
    const timeStamp =
      createAt &&
      createAt?.seconds &&
      moment(createAt.toDate()).format("ddd DD/MM/YYYY hh:mm:ss A");
    const fullName = item?.fullName;
    const email = item?.email;
    const phone = item?.phone;
    return (
      <TableRow hover key={index}>
        <TableCell align="left">{timeStamp}</TableCell>
        <TableCell align="left">{fullName}</TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{phone}</TableCell>
        {this.renderStatusButtonBar({ item })}
        {this.renderEditor({ item })}
      </TableRow>
    );
  };

  renderStatusButtonBar = ({ item }) => {
    const { event } = this.state;
    const status = item?.status;
    if (event?.vip) {
      return (
        <TableCell align="center" style={{ minWidth: 100 }}>
          <IconButton
            aria-label="deny"
            onClick={() => this.updateStatus({ item, status: EVENT_DENY })}
            style={{
              padding: 5,
              marginHorizontal: 10,
              opacity: status !== EVENT_DENY ? 0.5 : 1,
            }}
          >
            <BlockOutlined style={{ color: status !== EVENT_DENY ? "#ccc" : "red" }} />
          </IconButton>
          <IconButton
            aria-label="accept"
            onClick={() => this.updateStatus({ item, status: EVENT_APPROVE })}
            style={{
              padding: 5,
              marginHorizontal: 10,
              opacity: status !== EVENT_APPROVE ? 0.5 : 1,
            }}
          >
            <CheckCircleOutline style={{ color: status !== EVENT_APPROVE ? "#ccc" : "green" }} />
          </IconButton>
        </TableCell>
      );
    }
  };

  renderEditor = ({ item }) => {
    const { event } = this.state;
    const editor = item?.editor;
    if (event?.vip) {
      return <TableCell align="right">{editor}</TableCell>;
    }
  };

  renderTotal = () => {
    const { requests } = this.props;
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
        <Grid item style={{ fontSize: 17, marginTop: 15, color: "green" }}>
          Tổng: {requests?.length || 0}
        </Grid>
      </Grid>
    );
  };

  // -------------------------- MAIN --------------------------

  render() {
    return (
      <Grid container spacing={3} direction="row">
        <Grid item md={6} xs={6}>
          {this.renderSelectEvent()}
        </Grid>
        <Grid item md={6} xs={6}>
          {this.renderFilterBar()}
        </Grid>
        <Grid item md={6} xs={6}>
          {this.renderSearch()}
        </Grid>
        <Grid item md={6} xs={6}>
          {this.renderTotal()}
        </Grid>
        <Grid item md={12} xs={12}>
          {this.renderTable()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  requests: state.requestReducer.requests,
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreSnapRequest,
  unSnapRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestEvent);
