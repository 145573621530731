import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  TextField,
  CircularProgress,
  Paper,
  Button,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import moment from "moment";
import Navbar from "../00_Nav/nav_bar";
import NotificationDialogSuccess from "./dialogs/notification_dialog_success";
import {
  firestoreSnapNotification,
  unSnapNotification,
  firestoreAddNotification,
} from "../../store/actions/notification.action";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";

class NotificationScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      title: "",
      body: "",
      loading: false,
      dialogSuccess: false,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapNotification();
    this.props.firestoreSnapOperatorDetail();
  };

  componentWillUnmount = () => {
    this.props.unSnapNotification();
    this.props.unSnapOperatorDetail();
  };

  // ====================== Functions ====================== //

  // Dialog success

  closeDialogSuccess = () => {
    this.setState({
      dialogSuccess: false,
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  // ====================== Firebase Functions ====================== //

  sentNotification = () => {
    const { detail } = this.props;
    const { title, body } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        const username = detail?.username;
        this.props.firestoreAddNotification({ username, title, body }, () => {
          this.setState({
            title: "",
            body: "",
            loading: false,
            dialogSuccess: true,
          });
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p0");
    if (detail) {
      if (allow) {
        return (
          <main>
            {this.renderForm()}
            <h3>Lịch sử:</h3>
            {this.renderHistory()}
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderForm = () => {
    return (
      <Paper elevation={2} style={{ padding: 10 }}>
        <Grid container spacing={2}>
          {this.renderTitle()}
          {this.renderNote()}
          {this.renderButton()}
        </Grid>
      </Paper>
    );
  };

  renderTitle = () => {
    const { title } = this.state;
    const length = title ? 40 - title?.length : 40;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tiêu đề"
          variant="outlined"
          name="title"
          value={title}
          inputProps={{ maxLength: 40 }}
          onChange={this.handleChange}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderNote = () => {
    const { body } = this.state;
    const length = body ? 180 - body?.length : 180;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Nội dung"
          variant="outlined"
          name="body"
          value={body}
          onChange={this.handleChange}
          fullWidth
          inputProps={{ maxLength: 180 }}
          multiline
          rows={7}
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderButton = () => {
    const { loading } = this.state;
    return (
      <Grid
        item
        xs={12}
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 30,
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ color: "#fff", fontSize: 16 }}
          startIcon={loading ? <CircularProgress size={15} style={{ color: "#fff" }} /> : null}
          disabled={loading}
          onClick={() => this.sentNotification()}
        >
          Gửi thông báo
        </Button>
      </Grid>
    );
  };

  renderHistory = () => {
    const { notifications } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {notifications?.map((item, index) => this.renderItem({ item, index }))}
        </Grid>
      </Grid>
    );
  };

  renderItem = ({ item, index }) => {
    const createAt = item?.createAt;
    const time = createAt?.seconds ? moment(createAt.toDate()).format("DD/MM/YYYY hh:mm:ss A") : "";
    const username = item?.username;
    const title = item?.title;
    const body = item?.body;
    return (
      <Paper elevation={2} style={{ padding: 10, marginBottom: 10 }} key={index}>
        <p>
          {time} - {username}
        </p>
        <p>{title}</p>
        <p>{body}</p>
      </Paper>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { dialogSuccess } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="white">
          <Navbar title="Gửi thông báo" subtitle="Táo Vàng" right={null} />
          {this.renderBody()}
        </Container>
        <NotificationDialogSuccess
          dialogOpen={dialogSuccess}
          handleClose={this.closeDialogSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notificationReducer.notifications,
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreSnapNotification,
  unSnapNotification,
  firestoreAddNotification,
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NotificationScene);
