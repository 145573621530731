import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CssBaseline,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";
import RequestEvent from "./request_event";
import RequestChain from "./request_chain";

const TYPE = [
  {
    title: "Sự kiện đơn",
    value: 0,
  },
  {
    title: "Sự kiện chuỗi",
    value: 1,
  },
];

class RequestScreen extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {
      type: 0,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperatorDetail();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperatorDetail();
  };

  // -------------------------- FUNCTIONS --------------------------

  handleSelect = (e) => {
    const value = e.target.value;
    this.setState({
      type: value,
    });
  };

  // -------------------------- FIREBASE FUNCTIONS --------------------------

  // -------------------------- RENDER --------------------------

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p3");
    if (detail) {
      if (allow) {
        return (
          <main>
            <Paper style={{ marginBottom: 20, padding: 10 }}>
              <Grid container spacing={3} direction="row">
                <Grid item md={12} xs={12}>
                  {this.renderSelectType()}
                </Grid>
                <Grid item md={12} xs={12}>
                  {this.renderEventData()}
                </Grid>
              </Grid>
            </Paper>
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderSelectType = () => {
    const { type } = this.state;
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel htmlFor="age-native-required">Chọn Loại Sự Kiện</InputLabel>
        <Select value={type} name="type" onChange={this.handleSelect}>
          {TYPE?.map((item, index) => {
            const title = item?.title;
            const value = item?.value;
            return (
              <MenuItem value={value} key={index}>
                {title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  renderEventData = () => {
    const { type } = this.state;
    if (type === 0) {
      return <RequestEvent />;
    } else if (type === 1) {
      return <RequestChain />;
    }
  };

  // -------------------------- MAIN --------------------------

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="md">
          <Navbar title="Xét duyệt" right={<RightNav />} />
          {this.renderBody()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestScreen);
