import FileSaver from "file-saver";
import moment from "moment";
import numeral from "numeral";
import { BRAND_NAME } from "../../../config/config";
import { jsonToCSV } from "react-papaparse";

const columns = ["date", "email", "phone", "spent", "balance", "f0"];

export const exportToCSV = ({ report, user }) => {
  // =========================  Provider =========================
  // // =========================  Row Data =========================
  const csvData = converData({ report, user });
  const rowTableHeader = "Ngày,Email,Phone,Sử dụng,Còn lại,F0\n";
  const rowTableBody = jsonToCSV(csvData, { columns: columns, header: false, quotes: true });
  // // =========================  CSV =========================
  const csvBuffer = `${rowTableHeader}${rowTableBody}`;
  const currentTime = moment().format("DD_MM_YYYY-HH_mm_ss");
  const email = user?.email;
  const fileName = `${BRAND_NAME}-thong-tin-f1-cua-${email}-${currentTime}`;
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const converData = ({ report, user }) => {
  const result = report?.map((item) => {
    const refAt = item.refAt;
    const date = refAt && moment(refAt.toDate()).format("DD/MM/YYYY");
    // Users
    const email = item?.email;
    const phone = item?.phone;
    const spent = numeral(item?.spent).format("0,0");
    const balance = numeral(item?.balance).format("0,0");
    const f0 = user?.email;
    return {
      date,
      email,
      phone,
      spent,
      balance,
      f0,
    };
  });
  return result;
};
