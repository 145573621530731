import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { CssBaseline, Container, Grid } from "@material-ui/core";
import Navbar from "../00_Nav/nav_bar";
import {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
} from "../../store/actions/operator.action";
import { firestoreSnapChain, unSnapChain } from "../../store/actions/chain.action";
import ChainItem from "./component/chain_edit";
import ChainAdd from "./component/chain_add";

class ChainScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      title: "",
      body: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.firestoreSnapOperatorDetail();
    this.props.firestoreSnapChain();
  };

  componentWillUnmount = () => {
    this.props.unSnapOperatorDetail();
    this.props.unSnapChain();
  };

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderBody = () => {
    const { detail } = this.props;
    const permissions = detail?.permissions;
    const allow = permissions?.includes("p2");
    if (detail) {
      if (allow) {
        return (
          <main>
            <ChainAdd />
            <h3>Danh sách:</h3>
            {this.renderChains()}
          </main>
        );
      }
      return (
        <main>
          <p>Bạn không có quyền truy cập tính năng này</p>
        </main>
      );
    }
    return null;
  };

  renderChains = () => {
    const { chains } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {chains?.map((item, index) => (
            <ChainItem chain={item} key={index} />
          ))}
        </Grid>
      </Grid>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm" className="white">
          <Navbar title="Sự kiện chuỗi" subtitle="Táo Vàng" right={null} />
          {this.renderBody()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chains: state.chainReducer.chains,
  detail: state.operatorReducer.detail,
});

const mapDispatchToProps = {
  firestoreSnapOperatorDetail,
  unSnapOperatorDetail,
  firestoreSnapChain,
  unSnapChain,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ChainScene);
