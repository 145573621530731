import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { REF_EVENT, GET_EVENT_SUCCESS } from "../ref";

// -------------------------- Event Snap --------------------------
const subEvent = [];
export const firestoreSnapEvent = (callback) => (dispatch) => {
  const eventRef = firebase.firestore().collection(REF_EVENT);
  const today = moment().toDate();
  const unsub = eventRef
    .where("time", ">=", today)
    .orderBy("time", "asc")
    .limit(10)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getEventSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
  subEvent.push(unsub);
};

const getEventSuccess = (dispatch, data) => {
  dispatch({
    type: GET_EVENT_SUCCESS,
    payload: data,
  });
};

export const unSnapEvent = () => (dispatch) => {
  subEvent.forEach((subscriber) => {
    subscriber();
  });
  subEvent.length = 0;
};

// -------------------------- Event GET --------------------------

export const firestoreGetEvent = (callback) => {
  const eventRef = firebase.firestore().collection(REF_EVENT);
  const today = moment().toDate();
  eventRef
    .where("time", ">=", today)
    .orderBy("time", "desc")
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        if (callback) {
          callback(data);
        }
      }
    });
};

// -------------------------- Event Add --------------------------

export const firestoreAddEvent = ({ event }, callback) => {
  const eventRef = firebase.firestore().collection(REF_EVENT);
  const id = eventRef.doc().id;
  const data = {
    ...event,
    id,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  eventRef
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

// -------------------------- Event Update --------------------------
export const firestoreUpdateEvent = ({ event }) => {
  const eventRef = firebase.firestore().collection(REF_EVENT);
  const id = event.id;
  const data = {
    ...event,
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  eventRef.doc(id).update(data);
};

// -------------------------- Event Delete --------------------------
export const firestoreDeleteEvent = ({ event }) => {
  const eventRef = firebase.firestore().collection(REF_EVENT);
  const id = event.id;
  eventRef.doc(id).delete();
};
