export const MODE_SELECT = "select";
export const MODE_EDIT = "edit";
export const MODE_ADD = "add";
export const MODE_NONE = "none";
export const CAMERA = "camera";
export const UPLOAD = "upload";

export const PERMISSIONS = [
  { id: "p0", name: "Gửi thông báo" },
  { id: "p1", name: "Xem MLM" },
  { id: "p2", name: "Tạo sự kiện" },
  { id: "p3", name: "Duyệt sự kiện" },
];

export const EVENTS = [{ value: "zoom", name: "Zoom" }];

export const EVENT_ALL = "all";
export const EVENT_APPROVE = "approved";
export const EVENT_WAITING = "waiting";
export const EVENT_DENY = "deny";
