import firebase from "firebase/app";
import "firebase/firestore";
import {
  REF_REPORT,
  GET_REPORT_DRAW_SUCCESS,
  GET_REPORT_INVITATION_SUCCESS,
  GET_REPORT_JOURNEY_KID_SUCCESS,
  GET_REPORT_JOURNEY_PARENT_SUCCESS,
} from "../ref";

// -------------------------- Report Draw --------------------------
export const firestoreGetReportDraw =
  ({ from, to }, callback) =>
  (dispatch) => {
    const reportRef = firebase.firestore().collection(REF_REPORT);
    const start = from._d;
    const end = to._d;
    reportRef
      .where("type", "==", "campaign")
      .where("draw", "==", true)
      .where("date", ">=", start)
      .where("date", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getDrawSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getDrawSuccess = (dispatch, data) => {
  dispatch({
    type: GET_REPORT_DRAW_SUCCESS,
    payload: data,
  });
};

// -------------------------- Report INVIATION --------------------------
export const firestoreGetReportInvitation =
  ({ from, to }, callback) =>
  (dispatch) => {
    const reportRef = firebase.firestore().collection(REF_REPORT);
    const start = from._d;
    const end = to._d;
    reportRef
      .where("type", "==", "invitation")
      .where("date", ">=", start)
      .where("date", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getInvitationSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getInvitationSuccess = (dispatch, data) => {
  dispatch({
    type: GET_REPORT_INVITATION_SUCCESS,
    payload: data,
  });
};

// -------------------------- Report KID --------------------------
export const firestoreGetReportKid =
  ({ from, to }, callback) =>
  (dispatch) => {
    const reportRef = firebase.firestore().collection(REF_REPORT);
    const start = from._d;
    const end = to._d;
    reportRef
      .where("type", "==", "campaign")
      .where("journeyKid", "==", true)
      .where("date", ">=", start)
      .where("date", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getKidSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getKidSuccess = (dispatch, data) => {
  dispatch({
    type: GET_REPORT_JOURNEY_KID_SUCCESS,
    payload: data,
  });
};

// -------------------------- Report KID --------------------------
export const firestoreGetReportParent =
  ({ from, to }, callback) =>
  (dispatch) => {
    const reportRef = firebase.firestore().collection(REF_REPORT);
    const start = from._d;
    const end = to._d;
    reportRef
      .where("type", "==", "campaign")
      .where("journeyParent", "==", true)
      .where("date", ">=", start)
      .where("date", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getParentSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getParentSuccess = (dispatch, data) => {
  dispatch({
    type: GET_REPORT_JOURNEY_PARENT_SUCCESS,
    payload: data,
  });
};
