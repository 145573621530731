import firebase from "firebase/app";
import "firebase/firestore";
import { REF_REQUEST, GET_REQUEST_SUCCESS } from "../ref";

// -------------------------- Event Snap --------------------------
const subRequest = [];
export const firestoreSnapRequest =
  ({ eventId }, callback) =>
  (dispatch) => {
    const requestRef = firebase.firestore().collection(REF_REQUEST);
    const unsub = requestRef
      .where("event", "==", eventId)
      .orderBy("createAt", "asc")
      .onSnapshot((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getRequestSuccess(dispatch, data);
          if (callback) {
            callback();
          }
        }
      });
    subRequest.push(unsub);
  };

const getRequestSuccess = (dispatch, data) => {
  dispatch({
    type: GET_REQUEST_SUCCESS,
    payload: data,
  });
};

export const unSnapRequest = () => (dispatch) => {
  subRequest.forEach((subscriber) => {
    subscriber();
  });
  subRequest.length = 0;
};

// -------------------------- Request Update --------------------------
export const firestoreUpdateRequest = ({ id, status, editor }) => {
  const requestRef = firebase.firestore().collection(REF_REQUEST);
  const data = {
    status,
    editor,
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  requestRef.doc(id).update(data);
};
