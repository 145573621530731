import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Resizer from "react-image-file-resizer";
import placeholder from "../../../asset/img/placeholder.png";
import EventImageDialogUpload from "../dialogs/event_image_dialog_upload";

class EventImage extends Component {
  // ====================== Inits ====================== //
  constructor() {
    super();
    this.state = {
      dialogUpload: false,
    };
  }

  // ====================== Hàm xử lý validation ====================== //

  // ====================== Hàm xử lý Dialog ====================== //

  // ====================== Dialog Select ====================== //

  // ====================== Dialog Camera ====================== //

  // ====================== Dialog Upload ====================== //

  openUploadDialog = () => {
    this.setState({
      dialogUpload: true,
    });
  };

  closeUploadDialog = () => {
    this.setState({
      dialogUpload: false,
    });
  };

  confirmUploadSelect = (files) => {
    this.setState(
      {
        dialogUpload: false,
      },
      () => {
        Resizer.imageFileResizer(
          files[0],
          512,
          512,
          "jpg",
          96,
          0,
          (uri) => {
            this.props.handleChange(uri);
          },
          "base64"
        );
      }
    );
  };

  // ======================  function ====================== //

  renderDelete = () => {
    const { image, disabled } = this.props;
    if (image) {
      return (
        <div className="feature-delete">
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={() => this.props.handleChange("")}
          >
            <Delete fontSize="small" style={{ color: "red" }} />
          </IconButton>
        </div>
      );
    }
    return null;
  };

  // ====================== Render Component ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { image, disabled } = this.props;
    const { dialogUpload } = this.state;
    return (
      <div>
        <div
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          <img
            style={{
              width: image ? "100%" : 150,
              border: "1px solid #ccc",
            }}
            src={image || placeholder}
            alt="avatar-logo"
            onClick={() => this.openUploadDialog()}
          />
          {this.renderDelete()}
        </div>
        <EventImageDialogUpload
          dialogOpen={dialogUpload}
          handleClose={this.closeUploadDialog}
          handleConfirm={this.confirmUploadSelect}
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = {};

export default compose(connect(null, null))(EventImage);
