import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { orderBy } from "lodash";
import {
  REF_OPERATOR,
  GET_OPERATOR_SUCCESS,
  GET_OPERATOR_DETAIL_SUCCESS,
  LOCAL_USER,
} from "../ref";

// -------------------------- Operator Snap --------------------------
const subOperator = [];
export const firestoreSnapOperator = (callback) => (dispatch) => {
  const operatorRef = firebase.firestore().collection(REF_OPERATOR);
  const unsub = operatorRef.onSnapshot((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      const ordered = orderBy(data, ["createAt"], ["desc"]);
      getOperatorSuccess(dispatch, ordered);
      if (callback) {
        callback();
      }
    }
  });
  subOperator.push(unsub);
};

const getOperatorSuccess = (dispatch, data) => {
  dispatch({
    type: GET_OPERATOR_SUCCESS,
    payload: data,
  });
};

export const unSnapOperator = () => (dispatch) => {
  subOperator.forEach((subscriber) => {
    subscriber();
  });
  subOperator.length = 0;
};

// -------------------------- Operator Detail --------------------------

const subUser = [];
export const firestoreSnapOperatorDetail = (callback) => (dispatch) => {
  const operatorRef = firebase.firestore().collection(REF_OPERATOR);
  const id = localStorage.getItem(LOCAL_USER);
  const unsub = operatorRef.doc(id).onSnapshot((snapshot) => {
    if (snapshot.exists) {
      const data = snapshot.data();
      getDetailSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    } else {
      firebase.auth().signOut();
    }
  });
  subUser.push(unsub);
};

const getDetailSuccess = (dispatch, data) => {
  dispatch({
    type: GET_OPERATOR_DETAIL_SUCCESS,
    payload: data,
  });
};

export const unSnapOperatorDetail = () => (dispatch) => {
  subUser.forEach((subscriber) => {
    subscriber();
  });
  subUser.length = 0;
};
