import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { CssBaseline, Container, Grid, Button } from "@material-ui/core";
import {
  MicNoneOutlined,
  ShareOutlined,
  LiveTvOutlined,
  AssignmentTurnedInOutlined,
  AssignmentIndOutlined,
} from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import { URL } from "../../config/config";

const MENU = [
  { href: URL.notification, label: "Thông Báo", icon: <MicNoneOutlined /> },
  { href: URL.sharing, label: "Tra cứu F1", icon: <ShareOutlined /> },
  { href: URL.event, label: "Sự Kiện Đơn", icon: <LiveTvOutlined /> },
  { href: URL.chain, label: "Sự Kiện Chuỗi", icon: <LiveTvOutlined /> },
  { href: URL.request, label: "Xét duyệt", icon: <AssignmentTurnedInOutlined /> },
  { href: URL.report, label: "Thống kê", icon: <AssignmentIndOutlined /> },
  { href: URL.reportClient, label: "Người dùng App", icon: <AssignmentIndOutlined /> },
];

class DashboardScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {};
  }
  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderMenu = () => {
    return (
      <Grid container spacing={1}>
        {MENU?.map((item, index) => {
          const href = item.href;
          const label = item.label;
          const icon = item.icon;
          return (
            <Grid item sm={3} xs={4} key={index}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className="square-button"
                startIcon={icon}
                href={href}
                fullWidth
              >
                {label}
              </Button>
            </Grid>
          );
        })}
        {Array(10)
          .fill("")
          ?.map((item, index) => {
            return (
              <Grid item sm={3} xs={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className="square-button"
                  fullWidth
                >
                  ...
                </Button>
              </Grid>
            );
          })}
      </Grid>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    return (
      <div className="root-container">
        <CssBaseline />
        <Container maxWidth="sm">
          <Navbar title="Bảng điều khiển" subtitle="Táo Vàng" right={null} />
          <main>{this.renderMenu()}</main>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DashboardScene);
