import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class EmptyScene extends Component {
  // -------------------------- STATE --------------------------
  constructor() {
    super();
    this.state = {};
  }

  // -------------------------- FUNCTIONS --------------------------

  // -------------------------- MAIN --------------------------
  render() {
    return <Redirect to="/" />;
  }
}

export default EmptyScene;
