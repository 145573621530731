import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  TextField,
  Paper,
  Button,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
} from "@material-ui/core";
import moment from "moment";
import IOSSwitch from "../../../component/iosSwitch";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { firestoreUpdateChain } from "../../../store/actions/chain.action";
import { EVENTS } from "../../../config/stringConfig";
import ChainImage from "./chain_image";
import DialogDelete from "../dialogs/chain_dialog_delete";

class ChainItem extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      title: "",
      author: "",
      description: "",
      zoomId: "",
      password: "",
      zoomLink: "",
      address: "",
      image: null,
      type: "zoom",
      vip: false,
      loading: false,
      edit: false,
      dialogDelete: false,
    };
  }

  // ====================== Dialog ====================== //

  openDialogDelete = () => {
    this.setState({
      dialogDelete: true,
    });
  };

  closeDialogDelete = () => {
    this.setState({
      dialogDelete: false,
    });
  };

  // ====================== Functions ====================== //

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleTime = ({ id, value }) => {
    this.setState({
      [id]: value,
    });
  };

  handleImage = (image) => {
    this.setState({
      image,
    });
  };

  handleVip = () => {
    const { vip } = this.state;
    this.setState({
      vip: !vip,
    });
  };

  handleEdit = () => {
    const { chain } = this.props;
    const time = chain?.time?.seconds ? moment(chain?.time?.toDate()) : moment();
    this.setState({
      title: chain?.title,
      author: chain?.author,
      description: chain?.description,
      zoomId: chain?.zoomId,
      password: chain?.password,
      vip: chain?.vip,
      zoomLink: chain?.zoomLink,
      address: chain?.address,
      image: chain?.image,
      type: chain?.type,
      time,
      edit: true,
    });
  };

  stopEdit = () => {
    this.setState({
      edit: false,
    });
  };

  // ====================== Firebase Functions ====================== //

  handleSave = () => {
    const { chain } = this.props;
    const {
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      time,
      from,
      to,
      vip,
    } = this.state;
    const timeStamp = moment(time).toDate();
    const fromStamp = moment(from).startOf("day").toDate();
    const toStamp = moment(to).endOf("day").toDate();
    const update = {
      id: chain.id,
      title,
      author,
      description,
      zoomId,
      password,
      zoomLink,
      address,
      image,
      type,
      vip,
      pass: false,
      time: timeStamp,
      from: fromStamp,
      to: toStamp,
    };
    firestoreUpdateChain({ chain: update });
    this.setState({
      edit: false,
    });
  };

  handleActive = () => {
    const { chain } = this.props;
    const old = chain?.active || false;
    const update = {
      ...chain,
      active: !old,
    };
    firestoreUpdateChain({ chain: update });
  };

  // ====================== Render Component ====================== //

  renderHeader = () => {
    const { edit } = this.state;
    if (!edit) {
      return (
        <React.Fragment>
          <Grid item sm={3} xs={3}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                color: edit ? "#333" : "#fff",
                fontSize: 13,
                marginTop: 10,
                backgroundColor: edit ? "#ccc" : "#115293",
              }}
              onClick={() => this.handleEdit()}
            >
              Sửa
            </Button>
          </Grid>
          <Grid item sm={3} xs={3}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                color: edit ? "#333" : "#fff",
                fontSize: 13,
                marginTop: 10,
                backgroundColor: edit ? "#ccc" : "red",
              }}
              onClick={() => this.openDialogDelete()}
            >
              Xóa
            </Button>
          </Grid>
          <Grid item sm={6} xs={6}>
            {this.renderSwitch()}
          </Grid>
        </React.Fragment>
      );
    }
  };

  renderSwitch = () => {
    const { chain } = this.props;
    const value = chain?.active || false;
    const name = value ? "Đang chạy" : "Đang tắt";
    return (
      <FormControlLabel
        style={{ float: "right" }}
        className="draw-switch"
        control={<IOSSwitch checked={value} onChange={() => this.handleActive()} />}
        label={name}
      />
    );
  };

  renderType = () => {
    const { chain } = this.props;
    const { type, edit } = this.state;
    const value = edit ? type : chain?.type;
    return (
      <Grid item xs={12}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel htmlFor="age-native-required">Loại sự kiện</InputLabel>
          <Select disabled={!edit} value={value} name="type" onChange={this.handleChange}>
            {EVENTS?.map((item, index) => {
              return (
                <option value={item.value} key={index}>
                  Zoom
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderTime = () => {
    const { time, edit } = this.state;
    return (
      <Grid item xs={4}>
        <KeyboardTimePicker
          variant="inline"
          ampm={false}
          inputVariant="outlined"
          label="Thời gian"
          value={time}
          disabled={!edit}
          onChange={(value) => this.handleTime({ id: "time", value })}
          disablePast
          size="small"
          format="HH:mm"
          mask="__:__ _M"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderTime = () => {
    const { chain } = this.props;
    const { time, edit } = this.state;
    const eTime = chain?.time?.seconds ? moment(chain?.time?.toDate()) : moment();
    const selected = edit ? time : eTime;
    return (
      <Grid item xs={4}>
        <KeyboardTimePicker
          variant="inline"
          ampm={false}
          inputVariant="outlined"
          label="Thời gian"
          value={selected}
          disabled={!edit}
          onChange={(value) => this.handleTime({ id: "time", value })}
          disablePast
          size="small"
          format="HH:mm"
          mask="__:__ _M"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderFrom = () => {
    const { from, to, edit } = this.state;
    const { chain } = this.props;
    const eFrom = chain?.from?.seconds ? moment(chain?.from?.toDate()) : moment();
    const eTo = chain?.to?.seconds ? moment(chain?.to?.toDate()) : moment();
    const selectedFrom = edit ? from : eFrom;
    const selectedTo = edit ? to : eTo;
    return (
      <Grid item xs={4}>
        <KeyboardDatePicker
          variant="inline"
          ampm={false}
          inputVariant="outlined"
          label="Từ"
          value={selectedFrom}
          maxDate={selectedTo}
          disabled={!edit}
          onChange={(value) => this.handleTime({ id: "from", value })}
          size="small"
          format="DD/MM/YYYY"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderTo = () => {
    const { from, to, edit } = this.state;
    const { chain } = this.props;
    const eFrom = chain?.from?.seconds ? moment(chain?.from?.toDate()) : moment();
    const eTo = chain?.to?.seconds ? moment(chain?.to?.toDate()) : moment();
    const selectedFrom = edit ? from : eFrom;
    const selectedTo = edit ? to : eTo;
    return (
      <Grid item xs={4}>
        <KeyboardDatePicker
          variant="inline"
          ampm={false}
          inputVariant="outlined"
          label="Đến"
          value={selectedTo}
          minDate={selectedFrom}
          disabled={!edit}
          onChange={(value) => this.handleTime({ id: "to", value })}
          size="small"
          format="DD/MM/YYYY"
          disableToolbar={true}
        />
      </Grid>
    );
  };

  renderImage = () => {
    const { chain } = this.props;
    const { image, edit } = this.state;
    const value = edit ? image : chain?.image;
    return (
      <Grid item xs={12}>
        <ChainImage image={value} handleChange={this.handleImage} disabled={!edit} />
      </Grid>
    );
  };

  renderTitle = () => {
    const { chain } = this.props;
    const { title, edit } = this.state;
    const value = edit ? title : chain?.title;
    const length = value ? 100 - value?.length : 100;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tiêu đề"
          variant="outlined"
          size="small"
          name="title"
          value={value}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          disabled={!edit}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAuthor = () => {
    const { chain } = this.props;
    const { author, edit } = this.state;
    const value = edit ? author : chain?.author;
    const length = value ? 100 - value?.length : 100;
    return (
      <Grid item xs={8} className="count-string">
        <TextField
          label="Tác giả"
          variant="outlined"
          size="small"
          name="author"
          value={value}
          inputProps={{ maxLength: 100 }}
          onChange={this.handleChange}
          disabled={!edit}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderVip = () => {
    const { chain } = this.props;
    const { vip, edit } = this.state;
    const value = edit ? vip : chain?.vip;
    const name = value ? "Có phí" : "Miễn phí";
    return (
      <Grid item xs={4} className="count-string">
        <FormControlLabel
          style={{ float: "right" }}
          className="draw-switch"
          disabled={!edit}
          control={<IOSSwitch checked={value} onChange={() => this.handleVip()} />}
          label={name}
        />
      </Grid>
    );
  };

  renderDescription = () => {
    const { chain } = this.props;
    const { description, edit } = this.state;
    const value = edit ? description : chain?.description;
    const length = value ? 1000 - value?.length : 1000;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Tóm tắt"
          variant="outlined"
          size="small"
          name="description"
          value={value}
          required
          inputProps={{ maxLength: 1000 }}
          multiline
          rows={4}
          onChange={this.handleChange}
          disabled={!edit}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderAddress = () => {
    const { chain } = this.props;
    const { address, edit } = this.state;
    const value = edit ? address : chain?.address;
    const length = value ? 250 - value?.length : 250;
    return (
      <Grid item xs={12} className="count-string">
        <TextField
          label="Địa chỉ"
          variant="outlined"
          size="small"
          name="address"
          value={value}
          inputProps={{ maxLength: 250 }}
          onChange={this.handleChange}
          disabled={!edit}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{length}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderZoomId = () => {
    const { chain } = this.props;
    const { zoomId, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const value = edit ? zoomId : chain?.zoomId;
      return (
        <Grid item xs={12} className="count-string">
          <TextField
            label="ID Zoom "
            variant="outlined"
            size="small"
            name="zoomId"
            value={value}
            disabled={!edit}
            required
            inputProps={{ maxLength: 250 }}
            onChange={this.handleChange}
            fullWidth
          />
        </Grid>
      );
    }
  };

  renderZoomId = () => {
    const { chain } = this.props;
    const { zoomId, password, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const selectedId = edit ? zoomId : chain?.zoomId;
      const selectedPasword = edit ? password : chain?.password;
      return (
        <React.Fragment>
          <Grid item xs={6} className="count-string">
            <TextField
              label="ID Zoom"
              variant="outlined"
              size="small"
              name="zoomId"
              value={selectedId}
              required
              disabled={!edit}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} className="count-string">
            <TextField
              label="Zoom Password"
              variant="outlined"
              size="small"
              name="password"
              disabled={!edit}
              value={selectedPasword}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      );
    }
  };

  renderZoomLink = () => {
    const { chain } = this.props;
    const { zoomLink, type, edit } = this.state;
    const index = 0;
    if (type === EVENTS[index].value) {
      const value = edit ? zoomLink : chain?.zoomLink;
      return (
        <Grid item xs={12} className="count-string">
          <TextField
            label="Zoom Link"
            variant="outlined"
            size="small"
            name="zoomLink"
            value={value}
            disabled={!edit}
            onChange={this.handleChange}
            fullWidth
          />
        </Grid>
      );
    }
  };

  renderButtonSave = () => {
    const { edit } = this.state;
    if (edit) {
      return (
        <React.Fragment>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="small"
              fullWidth
              style={{
                fontSize: 13,
              }}
              onClick={() => this.stopEdit()}
            >
              Hủy
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              style={{
                fontSize: 13,
              }}
              onClick={() => this.handleSave()}
            >
              Lưu
            </Button>
          </Grid>
        </React.Fragment>
      );
    }
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { chain } = this.props;
    const { dialogDelete } = this.state;
    return (
      <Paper elevation={2} style={{ padding: 10, marginBottom: 30 }}>
        <Grid container spacing={2}>
          {this.renderHeader()}
          {this.renderType()}
          {this.renderTime()}
          {this.renderFrom()}
          {this.renderTo()}
          {this.renderImage()}
          {this.renderTitle()}
          {this.renderAuthor()}
          {this.renderVip()}
          {this.renderDescription()}
          {this.renderZoomId()}
          {this.renderZoomLink()}
          {this.renderAddress()}
          {this.renderButtonSave()}
        </Grid>
        <DialogDelete data={chain} dialogOpen={dialogDelete} handleClose={this.closeDialogDelete} />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ChainItem);
