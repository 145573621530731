import firebase from "firebase/app";
import "firebase/firestore";
import { orderBy } from "lodash";
import { REF_CLIENT, F1_LIST_SUCCESS } from "../ref";

// -------------------------- User Update --------------------------

export const firestoreGetF1 =
  ({ id, from, to }, callback) =>
  (dispatch) => {
    getF1Success(dispatch, []);
    const userRef = firebase.firestore().collection(REF_CLIENT);
    const start = from._d;
    const end = to._d;
    userRef
      .where("upline", "==", id)
      .where("refAt", ">=", start)
      .where("refAt", "<=", end)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const ordered = orderBy(data, ["refAt"], ["desc"]);
          getF1Success(dispatch, ordered);
        }
        if (callback) {
          callback();
        }
      });
  };

const getF1Success = (dispatch, data) => {
  dispatch({
    type: F1_LIST_SUCCESS,
    payload: data,
  });
};
