import firebase from "firebase/app";
import "firebase/firestore";
import { REF_CLIENT } from "../ref";

// -------------------------- User Get --------------------------

export const firestoreGetUserInfo =
  ({ email }, callback) =>
  (dispatch) => {
    const emailLower = String(email).toLowerCase();
    const userRef = firebase.firestore().collection(REF_CLIENT);
    userRef
      .where("email", "==", emailLower)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const user = data[0];
          if (user) {
            if (callback) {
              callback(user);
            }
          } else {
            if (callback) {
              callback(false);
            }
          }
        }
      });
  };
