import React, { Component } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Router from "./screen/00_Nav/router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/vi";
moment().locale("vi");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#f3a817",
    },
    secondary: {
      main: "#e07121",
    },
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <Router />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default App;
