import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { REF_CHAIN, GET_CHAIN_SUCCESS } from "../ref";

// --------------------------  Snap --------------------------

const subChain = [];
export const firestoreSnapChain = (callback) => (dispatch) => {
  const chainRef = firebase.firestore().collection(REF_CHAIN);
  const today = moment().toDate();
  const unsub = chainRef
    .where("to", ">=", today)
    .orderBy("to", "asc")
    .limit(10)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getChainSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      }
    });
  subChain.push(unsub);
};

const getChainSuccess = (dispatch, data) => {
  dispatch({
    type: GET_CHAIN_SUCCESS,
    payload: data,
  });
};

export const unSnapChain = () => (dispatch) => {
  subChain.forEach((subscriber) => {
    subscriber();
  });
  subChain.length = 0;
};

// --------------------------  GET --------------------------
export const firestoreGetChain = (callback) => {
  const chainRef = firebase.firestore().collection(REF_CHAIN);
  const today = moment().toDate();
  chainRef
    .where("to", ">=", today)
    .orderBy("to", "desc")
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        if (callback) {
          callback(data);
        }
      }
    });
};
// --------------------------  Add --------------------------

export const firestoreAddChain = ({ chain }, callback) => {
  const chainRef = firebase.firestore().collection(REF_CHAIN);
  const id = chainRef.doc().id;
  const data = {
    ...chain,
    id,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  chainRef
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

// --------------------------  Update --------------------------
export const firestoreUpdateChain = ({ chain }) => {
  const chainRef = firebase.firestore().collection(REF_CHAIN);
  const id = chain.id;
  const data = {
    ...chain,
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  chainRef.doc(id).update(data);
};

// --------------------------  Delete --------------------------
export const firestoreDeleteChain = ({ chain }) => {
  const chainRef = firebase.firestore().collection(REF_CHAIN);
  const id = chain.id;
  chainRef.doc(id).delete();
};
