import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { SYSTEM_EMAIL, SYSTEM_PASSWORD } from "../../config/config";
import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT_SUCCESS, LOCAL_USER } from "../ref";

// -------------------------- LOGIN --------------------------
export const firebaseLogin =
  ({ id }) =>
  (dispatch) => {
    firebaseLoading(dispatch);
    firebase
      .auth()
      .signInWithEmailAndPassword(SYSTEM_EMAIL, SYSTEM_PASSWORD)
      .then(() => {
        localStorage.setItem(LOCAL_USER, id); // lưu id vào local
        firebaseLoginSuccess(dispatch);
      })
      .catch((error) => firebaseLoginFail(dispatch, error.message)); // Lỗi
  };

const firebaseLoading = (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
  });
};

const firebaseLoginSuccess = (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
  });
};

const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: LOGIN_FAILED,
    payload: error,
  });
};

// Logout
export const firebaseLogout = () => (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => firebaseLogoutSuccess(dispatch)); // Thành công
};

const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};
