import {
  GET_REPORT_DRAW_SUCCESS,
  GET_REPORT_INVITATION_SUCCESS,
  GET_REPORT_JOURNEY_KID_SUCCESS,
  GET_REPORT_JOURNEY_PARENT_SUCCESS,
} from "../ref";

const initState = {
  draws: null,
  invitations: null,
  kids: null,
  parents: null,
};

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_REPORT_DRAW_SUCCESS:
      return {
        ...state,
        draws: action.payload,
      };
    case GET_REPORT_INVITATION_SUCCESS:
      return {
        ...state,
        invitations: action.payload,
      };
    case GET_REPORT_JOURNEY_KID_SUCCESS:
      return {
        ...state,
        kids: action.payload,
      };
    case GET_REPORT_JOURNEY_PARENT_SUCCESS:
      return {
        ...state,
        parents: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
