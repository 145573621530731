import React, { Component } from "react";
import { connect } from "react-redux";
import { IconButton, Badge, Link } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { URL } from "../../config/config";

class RightNav extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {};

  componentWillUnmount = () => {};

  render() {
    const { pendingRequest } = this.props;
    const length = pendingRequest?.length;
    return (
      <Link href={URL.request}>
        <IconButton aria-label="cart">
          <Badge badgeContent={length} color="secondary">
            <Email style={{ color: "#fff" }} />
          </Badge>
        </IconButton>
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RightNav);
